@import "../../assets/styles/utils";
.subsidiary {
    box-sizing: border-box;
    @include res(padding-left,7.1875%,(xs:10px));
    @include res(padding-right,7.1875%,(xs:10px));
    .content {
        @include res(margin-top,70px,(3 / 7));
        .mySwiper2 {
            @include res(width,844px,(sm:90%));
            img {
                width: 100%;
                max-width: 100%;
                margin: auto;
                aspect-ratio: 844 / 552;
            }
            p {
                text-align: center;
                font-family: SourceHanSansCN-Light;
                @include res(font-size,18px,(16 / 18));
                letter-spacing: 1px;
                color: #000000;
                @include res(margin-top,20px,(3 / 4));
            }
        }
        .swiper-list {
            position: relative;
            @include res(margin-top,82px,(30 / 82));
            @include res(margin-bottom,97px,(30 / 97));
            .swiper {
                @include res(width,93%);
                height: 110%;
                .swiper-slide {
                    img {
                        aspect-ratio: 249 / 160;
                        width: 100%;
                        max-width: 100%;
                        transform: scale(0.95);
                        transition: all .3s;
                    }
                    &.swiper-slide-thumb-active {
                        img {
                            transform: scale(1);
                        }
                    }
                }
            }
            .swiper-btn {
                width: 34px;
                height: 34px;
                border: solid 1px #dcdcdc;
                border-radius: 50%;
                transition: all .3s;
                span {
                    color: #dcdcdc;
                    transition: all .3s;
                }
                &:hover {
                    border-color: #014099;
                    span {
                        color:#014099;
                    }
                }
                &.swiper-button-next {
                    @include res(right,10,(mmd:-10px,xs:0));
                }
                &.swiper-button-prev {
                    @include res(left,10px,(mmd:-10px,xs:0));
                }
            }
            .swiper-pagination {
                @include res(bottom,-38px,(1 / 2));
                .swiper-pagination-bullet-active {
                    background: #014099;
                }
            }
        }
    }
}